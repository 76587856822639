<template>
  <div class="PlaceOrder">
    <van-sticky style="width: 100%">
      <van-nav-bar title="ORDER DETAILS"></van-nav-bar>
    </van-sticky>

    <div class="content">
      <div class="hotelInfo" v-if="hotelInfo.name">
        <p>{{ hotelInfo.name }}</p>
        <p>
          {{ hotelInfo.address }}, {{ hotelInfo.city }}, {{ hotelInfo.state }}
          {{ hotelInfo.zipcode }}<br />{{ hotelInfo.phone }}
        </p>
      </div>
      <template>
        <van-cell-group inset v-if="order">
          <van-cell
            class="bold-divider-cell center-cell bold-text"
            title="Check #"
            :value="order.check"
          />
          <van-cell
            style="padding: 10px 16px 2px"
            title="Menu"
            :value="foodDetail.mealPeriod"
          />
          <van-cell
            style="padding: 2px 16px"
            title="Order Type"
            :value="foodDetail.orderType"
          />
          <van-cell
            style="padding: 2px 16px"
            title="Payment Type"
            :value="foodDetail.payMethod"
          />
          <template v-if="foodDetail.chargeRoom">
            <van-cell
              style="padding: 2px 16px"
              title="Room #"
              :value="foodDetail.chargeRoom"
            />
            <van-cell
              style="padding: 2px 16px"
              title="Table #"
              :value="foodDetail.roomNum"
            />
          </template>
          <van-cell
            v-else
            style="padding: 2px 16px"
            title="Room #"
            :value="foodDetail.roomNum"
          />
          <van-cell
            class="sp-van-cell"
            style="padding: 2px 16px"
            title="Order Time"
            :value="foodDetail.orderTime"
          />
          <van-cell
            class="sp-van-cell"
            style="padding: 2px 16px"
            title="Requested Time"
            :value="foodDetail.time"
          />
          <van-cell
            class="bold-divider-cell"
            style="padding: 2px 16px 10px"
            title="Number Of Guests"
            :value="foodDetail.guestNum"
          />
          <van-cell
            class="bold-divider-cell"
            v-if="foodDetail.remarks"
            title="Additional Comments"
          >
            <template #label>
              <p class="" style="font-size: 15px; color: #01688a">
                {{ foodDetail.remarks }}
              </p>
            </template>
          </van-cell>
          <van-cell
            v-for="(food, index) in foodDetail.shoppingFood"
            :key="food.id"
            :title="food.num + ' * ' + food.name"
            :value="'$' + food.allPrice.toFixed(2)"
            :style="{
              padding:
                foodDetail.shoppingFood.length === 1
                  ? '10px 16px'
                  : index === 0
                  ? '10px 16px 5px'
                  : index === foodDetail.shoppingFood.length - 1 &&
                    !foodDetail.promoFee
                  ? '0px 16px 10px'
                  : '0px 16px 5px',
            }"
            :class="{
              'bold-divider-cell':
                index === foodDetail.shoppingFood.length - 1 &&
                !foodDetail.promoFee,
              shoppingFood: true,
            }"
          >
            <template #label>
              <div>
                <div
                  v-for="(sideDish, index) in food.sideDishList"
                  :key="index"
                >
                  <div v-for="option in sideDish.optionList" :key="option.id">
                    <div
                      style="padding-top: 3px"
                      v-if="isShowOption(food, option.id)"
                      :key="option.id"
                    >
                      <span
                        style="font-size: 15px; color: #484848"
                        class="word-break"
                        >-- ({{ sideDish.title }}) {{ option.name }}</span
                      >
                    </div>
                  </div>
                </div>
                <div v-if="food.remark" style="font-size: 15px; color: #01688a">
                  (Note) {{ food.remark }}
                </div>
              </div>
            </template>
          </van-cell>
          <van-cell
            v-if="foodDetail.promoFee"
            :title="'Discount: ' + foodDetail.promoFeeName"
            :value="'-$' + foodDetail.promoFee.toFixed(2)"
            style="padding: 0px 16px 10px"
            class="bold-divider-cell shoppingFood"
          />
          <van-cell
            style="padding: 10px 16px 2px"
            title="Subtotal"
            :value="'$' + foodDetail.subTotal.toFixed(2)"
          />
          <van-cell
            v-for="(fee, index) in foodDetail.feeList"
            :key="fee.id"
            :title="fee.fee"
            :value="'$' + fee.value.toFixed(2)"
            style="font-size: 15px"
            :style="{
              padding:
                index === foodDetail.feeList.length - 1
                  ? '2px 16px 10px'
                  : '2px 16px',
              fontSize: '15px',
            }"
            :class="{
              'bold-divider-cell': index === foodDetail.feeList.length - 1,
              feeList: true,
              shoppingFood: true,
            }"
          />
          <van-cell
            v-if="foodDetail.addTip"
            :title="'Additional Tip'"
            :value="'$' + foodDetail.addTip.toFixed(2)"
            style="padding: 10px 16px"
            class="bold-divider-cell shoppingFood"
          />
          <van-cell
            :class="{
              'bold-divider-cell': foodDetail.remarks,
              'bold-text': true,
            }"
            title="TOTAL"
            :value="'$' + foodDetail.total.toFixed(2)"
          />
        </van-cell-group>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { postAction } from "@/api/manage";
import moment from "moment-timezone";

export default {
  name: "Order",
  components: {},
  data() {
    return {
      url: {
        getOrderDetail:
          "/api/common/hotel/data/sub_order/getHotelAndSubOrderById/",
      },
      orderId: "",
      order: null,
      hotelInfo: {},
      foodDetail: {},
    };
  },
  created() {
    this.orderId = this.$route.query.id;
    this.getOrderDetail();
  },
  methods: {
    moment,
    ...mapActions(["editColor"]),
    getOrderDetail() {
      postAction(`${this.url.getOrderDetail}${this.orderId}`).then((res) => {
        const { hotelInfo, subOrder } = res.data;
        let detail = JSON.parse(subOrder.foodDetail);
        console.log("detail", detail);
        this.order = {
          ...subOrder,
          check: subOrder.serviceKey && subOrder.serviceKey.split("Check")[1],
        };

        this.hotelInfo = hotelInfo;
        this.foodDetail = {
          ...detail,
          orderTime: moment(detail.orderTime)
            .tz(hotelInfo.timeZone)
            .format("MM/DD/YYYY h:mm A"),
        };

        if (!this.foodDetail.time.includes("ASAP")) {
          this.$set(
            this.foodDetail,
            "time",
            moment(this.foodDetail.readyTime)
              .tz(hotelInfo.timeZone)
              .format("MM/DD/YYYY h:mm A")
          );
        }

        console.log("foodDetail", this.foodDetail);
        if (hotelInfo.color) this.editColor("#" + hotelInfo.color);
      });
    },
    isShowOption(food, id) {
      for (let i = 0; i < food.foods.length; i++) {
        const element = food.foods[i];
        if (element.checked.indexOf(id) != -1) {
          return true;
        }
      }
      if (id == food.drinksChildrenChecked) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.PlaceOrder {
  .word-break {
    word-break: normal;
  }

  height: 100%;
  background: #f5f4f4;
  display: flex;
  flex-flow: column;
  align-items: center;

  /deep/ .van-nav-bar {
    background-color: $primary-color;
    height: 35pt;
    width: 100%;

    .van-nav-bar__title {
      color: #fff;
      font-size: 14pt;
    }
  }

  /deep/ .van-grid-item__content {
    background: #f5f4f4;
    padding: 17pt 3.75pt 7.5pt;
  }

  /deep/ .van-nav-bar__content {
    height: 100%;
  }

  .content {
    width: calc(100% - 12pt);
    height: calc(100% - 35pt);
    padding: 10pt;
    overflow: auto;

    /deep/ .van-cell-group--inset {
      margin: 0;
      margin-top: 10pt;
      margin-bottom: 10px;

      .food-cell {
        .van-cell__title {
          flex-grow: 2;
        }

        .van-cell__value {
          flex-grow: 1;
        }
      }
    }

    /deep/ .van-cell__title {
      text-align: left;
      font-size: 12pt;
      font-family: PingFang SC;
      color: #2b2e36;
      word-break: normal;
      font-weight: normal;
    }

    /deep/ .van-cell__value {
      font-size: 12pt;
      font-family: PingFang SC;
      color: #636464;
      word-break: normal;
      font-weight: normal;
    }

    /deep/ .van-cell__label {
      padding-left: 15pt;
      margin-top: 0;
    }

    .bold-text {
      /deep/ .van-cell__title {
        font-family: PingFangSC-Semibold !important;
        font-weight: bold;
      }
      /deep/ .van-cell__value {
        font-family: PingFangSC-Semibold !important;
        font-weight: bold;
        color: #2b2e36 !important;
      }
    }
  }

  .bold-divider-titel-cell {
    &:after {
      position: absolute;
      content: "";
      border-bottom: 4px solid #dfdfdf;
    }
  }

  .bold-divider-cell {
    &:after {
      position: absolute;
      content: "";
      border-bottom: 3px solid #dfdfdf;
    }
  }

  .center-cell {
    padding-left: 80pt;
    padding-right: 80pt;
    /deep/ .van-cell__title {
      span {
        white-space: nowrap;
      }
    }
  }

  .divider {
    height: 2px;
    width: calc(100% - 20pt);
    background-color: #dfdfdf;
    margin-left: 10pt;
  }

  .bold-divider-total-cell {
    position: relative;

    /deep/ .van-cell__title {
      font-weight: bold;
    }

    /deep/ .van-cell__value {
      font-weight: bold;
    }
  }

  .shoppingFood {
    /deep/ .van-cell__title {
      font-size: 16px;
      width: 75%;
      line-height: 20px;
      flex: none;
    }

    /deep/ .van-cell__value {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .feeList {
    /deep/ .van-cell__title {
      font-size: 15px;
      color: #484848;
    }

    /deep/ .van-cell__value {
      font-size: 15px;
      color: #484848;
    }
  }

  .remark {
    font-size: 10pt;
    padding-left: 15pt;
    line-height: 12pt;
    margin-bottom: 0;
    margin: 3pt 0;
  }

  .sp-van-cell {
    /deep/ .van-cell__title {
      width: 35%;
      flex: none;
    }

    /deep/ .van--cell__value {
      width: 65%;
      flex: none;
    }
  }
}

.hotelInfo {
  width: 100%;
  padding: 10px 14% 6px;
  background-color: #fff;
  margin-top: 10pt;
  border-radius: 8px;
  font-weight: bold;

  p {
    font-size: 12pt;
    color: #2b2e36;
    text-align: center;
    font-family: PingFangSC-Semibold;
  }
}
</style>
